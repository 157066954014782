<template>
  <b-card>
    <b-row class="justify-content-between">
      <b-col class="pr-md-32 pr-md-120">
        <h4 class="mb-0">Basic</h4>
      </b-col>

      <b-col class="hp-flex-none w-auto">
        <b-button
          @click="codeClick()"
          variant="text"
          class="btn-icon-only show-code-btn"
        >
          <i
            class="ri-code-s-slash-line hp-text-color-black-80 hp-text-color-dark-30 lh-1"
            style="font-size: 16px"
          ></i>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <div class="col-12 mt-16">
        <!-- Using props -->
        <b-input-group size="lg" prepend="$" append=".00">
          <b-form-input></b-form-input>
        </b-input-group>

        <!-- Using slots -->
        <b-input-group class="mt-3">
          <template #append>
            <b-input-group-text>
              <strong class="text-danger">!</strong>
            </b-input-group-text>
          </template>
          <b-form-input></b-form-input>
        </b-input-group>

        <!-- Using components -->
        <b-input-group prepend="Username" class="mt-3">
          <b-form-input></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary">Button</b-button>
            <b-button variant="primary">Button</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <div
        v-if="codeActive"
        class="col-12 mt-24 hljs-container"
        :class="{ active: codeActiveClass }"
      >
        <pre v-highlightjs>
          <code class="hljs html">
            {{ codeText }}
          </code>
        </pre>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BInputGroup,
  BInputGroupText,
  BInputGroupAppend,
  BFormInput,
} from "bootstrap-vue";

import code from "./code";

export default {
  data() {
    return {
      text: "",
      codeText: code.basic,
      codeActive: false,
      codeActiveClass: false,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BFormInput,
  },
  methods: {
    codeClick() {
      this.codeActive = !this.codeActive;

      setTimeout(() => {
        this.codeActiveClass = !this.codeActiveClass;
      }, 100);
    },
  },
};
</script>
